<template>
  <page-section
      :title="$t('wsu_homepage.modules.title')"
      :subtitle="$t('wsu_homepage.modules.subtitle')"
  >
    <v-row class="mt-12">
      <v-col cols="12" md="6" v-for="(icon, item ) in casesSelect" :key="item" >
        <div style="border : 2px solid var(--wsBACKGROUND)" class="d-flex fill-height align-center pa-6 wsRoundedSemi">
          <v-icon large :color="wsATTENTION" class="mr-4">
            {{ icon }}
          </v-icon>
          <h3 style="font-size: 19px" :style="`color : ${wsDARKER}`" >
            {{ $t(`wsu_homepage.modules.${item}.title`) }}
          </h3>
        </div>

      </v-col>
    </v-row>
  </page-section>
</template>

<script>
export default {
  name: "homeSectionModules",
  data() {
    return {
      casesSelect : {
        documentflow : 'mdi-file-multiple',
        enrollment_documents : 'mdi-account-box-outline',
        enrollment : 'mdi-account-multiple',
        cloud : 'mdi-cloud-check-variant',
        newsletter : 'mdi-email-arrow-right',
        lms : 'mdi-cast-education',
        gradebook : 'mdi-bookmark-box-multiple',
        schedule : 'mdi-calendar',
        accounting : 'mdi-credit-card',
        rectorate : 'mdi-monitor-account',
        courses : 'mdi-school',
        webinars : 'mdi-laptop-account',
        conference : 'mdi-badge-account-outline',
        science : 'mdi-card-account-details-star',
        project_manager : 'mdi-file-tree',
        workplace : 'mdi-monitor-dashboard',
      }
    }
  }
}
</script>

<style scoped>

</style>